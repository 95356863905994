body {
    margin: 0;
    font-family: "Poppins", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body.modalOpen {
    overflow: hidden;
}
.IAButton {
    display: flex;
    align-items: center;
    gap: 12px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.no-table-data p {
    text-align: center;
    font-size: 0.9rem;
}
