.divider {
    height: 2px;
    background: #ebecf0;
    margin: 0px 16px;
}

.action-container {
    padding: 16px 16px 12px 16px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;
}

.body {
    & :global .MuiAccordion-root::before {
        background-color: transparent;
    }

    & :global .MuiPaper-root {
        padding: 0px;
        &.filterGroup {
            padding-bottom: 8px;
        }
    }

    & :global .MuiPaper-root.filterGroup label {
        display: block;
        text-align: left;
        font: normal normal normal 12px Poppins, sans-serif;
        letter-spacing: 0px;
        color: #758490;
    }

    .filter-container {
        margin-top: 20px;
        border: 1px solid #c8ced7;
        border-radius: 3px;
    }

    .or-container {
        margin-top: 24px;

        .or-content {
            margin: 0px 20px;
            font-weight: 600;
        }
    }

    .copy-section {
        margin-top: 16px;
        padding: 16px 18px 18px;
        text-align: left;
        border: 1px solid #c8ced7;
        border-radius: 3px;

        .title {
            margin-bottom: 16px;
            font-weight: 600;
            line-height: 24px;
        }

        .desc {
            margin-bottom: 12px;
            color: #333;
            font-size: 14px;
        }
    }
}

.drawer-container {
    position: relative;
    overflow-y: scroll;
    padding: 20px 32px;
    background-color: #fff;

    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }

    .close-icon {
        color: #999;
    }

    .sub-title {
        font-size: 12px;
        white-space: break-spaces;
    }

    .dropdown {
        width: 300px;
    }

    .paste-area {
        display: block;
        width: 100%;
        height: 180px;
        padding: 8px 16px;
        font: normal normal normal 12px/26px Poppins, sans-serif;
        resize: none;
        box-sizing: border-box;
        border-color: #acacac;

        &::placeholder {
            color: #999;
        }
    }

    .invalid-values-container {
        width: 100%;
        max-height: 180px;
        padding: 8px 16px;
        color: #555;
        box-sizing: border-box;
        white-space: break-spaces;
        border: 1px solid #acacac;
        border-radius: 3px;
        overflow: auto;
    }
}

.planning-roll-up-container {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.tertiary-button {
    font-size: 14px;
    font-weight: bold;
}

.filter-showcase-container {
    margin-top: 15px;
    padding: 10px 16px;
    border: 1px solid #c8ced7;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-wrap: nowrap;
}

.filter-showcase-details {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 11 */
}

.filter-showcase-details::-webkit-scrollbar {
    display: none;
}

.filter-section {
    margin-left: 10px;
    background-color: #f3f7fe;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-radius: 3px;
}

.filter-title {
    font: normal normal 400 14px "Poppins", sans-serif;
    color: #1d1d1d;
    font-weight: bold;
    margin-right: 10px;
}

.filter-items {
    padding: 2px 5px;
    margin-left: 5px;
    font: normal normal 400 14px "Poppins", sans-serif;
    color: #78828f;
    background-color: #ebecf0;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.filter-showcase-navigation {
    display: flex;
    margin-left: 3px;

    button {
        background-color: #ebecf0;
        padding: 8px;
        border: none;
        height: 44px;
        cursor: pointer;
        color: #78828f;
    }

    button:nth-child(1) {
        margin-right: 2px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    button:nth-child(2) {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}

.cross-icon {
    margin-left: 5px;
    font-size: 20px;
}

.input-label {
    margin-right: 10px;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 26px;
}
