/* markdown css */

.sku-list-main-div {
    display: flex;
    justify-content: space-evenly;
    margin-top: 30px;
}
.skudiv-width {
    padding: 8px;
    width: 16.6rem;
}

.sku-list-container {
    background: #f7fafc 0 0 no-repeat padding-box;
    border: 1px solid #9fb8c7;
    opacity: 1;
}
.sku-list-inn {
    display: flex;
    justify-content: center;
    line-height: 20px;
}
/* sku table css */
.sku-tablein {
    margin-left: 58px;
    width: 71px;
    height: 22px;
    background: #e59393;
    border-radius: 3px;
    color: #ffffff;
    opacity: 1;
}
.skutable-style {
    font-size: 14px;
    width: 100%;
    text-align: center;
}
.skutable-tr {
    background-color: rgba(68, 103, 123, 0.1);
    color: #4b6d80;
}
.tr-style-sku-table {
    line-height: 28px;
    border-bottom: 1px solid #e6e6e6;
}
.td-color {
    color: #007bff;
}
.btn-red {
    padding: 1px 2px;
    font-size: 11px !important;
    background: #e59393;
    font-weight: bold;
    color: white;
}
.btn-yellow {
    padding: 1px 2px;
    font-size: 11px !important;
    background: #d6b93e;
    font-weight: bold;
    color: white;
}
.color-blue {
    color: #44677b;
}
.text-deco {
    text-decoration: underline !important;
}
.font-600 {
    font-weight: 600;
}

.senariosValue {
    background: #dce6eb 0% 0% no-repeat padding-box;
    border-radius: 3px;
    opacity: 1;
    text-align: center;
    width: 110px;
    height: 36px;
}
.btn-close-set {
    border: 0px;
    padding: 0px 4px;
}
.skuLabel {
    font-size: 15px;
    font-weight: bold;
    padding-left: 3rem;
    color: #000000;
}
.css-12zlm52-ReactDropdownSelect {
    position: relative;
    box-sizing: border-box;
    width: 180px !important;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: #ccc;
    border-radius: 4px !important;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 41px !important;
    outline: 0 !important;
    -webkit-transition: all 100ms;
    transition: all 100ms;
}

.css-12zlm52-ReactDropdownSelect:hover,
.css-12zlm52-ReactDropdownSelect:focus-within {
    border-color: #44677b !important;
    border: 1px solid #44677b !important;
}
.metric-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -3rem;
}
.summary-metric-container {
    position: absolute;
    top: 0;
    left: 48%;
}
