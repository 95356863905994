// Loader component styles
.loader-fullscreen {
    z-index: 12;
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: white;
}
