/* New Filter */

/* ---- fixes for issues in react-select library ----- */
input[id^="react-select-"] {
    opacity: 1 !important;
}

/* ------ products group styles for dropdown menu ------ */
.multi-select-container {
    .multi-select__control--menu-is-open,
    .multi-select__control {
        display: none; //hide value container and provide custom search bar
    }

    .multi-select__menu {
        box-shadow: none;
        padding: 0 0 8px 0;

        .multi-select__menu-list {
            .multi-select__option {
                // height: 28px;
                position: relative;
                padding: 0px 12.5px;

                .checkbox {
                    display: flex;
                    padding: 4px 0px;
                    font-size: 10px;
                    color: #4d4d4d;
                    // height: 28px;
                    align-items: flex-start;
                    cursor: pointer !important;

                    input[type="checkbox"] {
                        width: 12px;
                        height: 12px;
                        cursor: pointer !important;
                    }
                    label {
                        margin-bottom: 0;
                        padding-left: 4px;
                        padding-bottom: 0;
                        cursor: pointer !important;
                        font: normal normal normal 12px/20px Poppins, sans-serif;
                        text-align: left;
                    }
                }
            }

            .multi-select__option--is-selected {
                background-color: white !important;

                &.single-select.highlight {
                    background-color: #0479fa !important;

                    & * {
                        color: white !important;
                    }
                }

                .checkbox {
                    label {
                        color: #4d4d4d;

                        &:before {
                            background-color: #0479fa !important;
                        }

                        &:after {
                            border-bottom: 2px solid white;
                            border-left: 2px solid white;
                        }
                    }
                }
            }
        }
    }
}

.filter-options-group {
    .filter-options-item {
        display: flex;
        align-items: center;
        text-align: left;
        font: normal normal normal 14px/24px Poppins, sans-serif;
        letter-spacing: 0px;
        color: #758490;
        padding: 2px 14px 0;

        .filter-options-item-icon {
            padding: 0 8px;
            line-height: 10px;
        }

        .filter-options-item-text {
            margin-right: 10px;
            padding: 2px;
            width: 100%;
            height: 100%;
            cursor: pointer;
            display: contents;
            //font-size: 11px;

            &:hover {
                background-color: #deebff;
            }
        }
    }
}

// hide checkbox
.single-select-with-no-checkbox {
    .multi-select-container {
        .multi-select__menu {
            padding: 0;
            .multi-select__menu-list {
                .multi-select__option {
                    width: 100%;
                    margin: 0;
                    background-color: #fff;
                    .checkbox {
                        &:before {
                            border: none;
                        }
                        input[type="checkbox"] {
                            display: none;
                        }
                    }
                    &:hover {
                        background-color: #deebff !important;
                    }
                }
                .multi-select__option--is-selected {
                    border: none !important;
                    background-color: #deebff !important;
                }
            }
        }
    }
}

/* Filter Title Button Styles */
.filter-title-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
}

.filter-title-button-container p.filter-page-title {
    text-align: left;
    font: normal normal 600 18px/27px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #1d1d1d;
    margin: 16px 0;
}

.filter-title-button-container button.select-filters-button {
    width: 148px;
    height: 36px;
    background: #0055af 0% 0% no-repeat padding-box;
    border-radius: 3px;
    margin: 16px 0;
}

/* Filter Chips Summary Styles */
.filter-summary-container {
    max-height: 74px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c8ced7;
    border-radius: 3px;
    // margin: 0 25px;
    padding: 9px 12px;
    text-align: left;
    overflow: auto;
}

.filter-summary-container label.chip-label {
    text-align: left;
    font: normal normal normal 12px/18px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #758498;
    margin-right: 20px;
}

.filter-summary-container .summary-chip {
    margin: 3px 5px 3px 0px;
    text-align: left;
    font: normal normal normal 12px/18px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #758498;
    height: 30px;

    &.last {
        margin-right: 20px;
    }
}

/* Filter Modal Styles */
.filter-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c8ced7;
    border-radius: 3px;
    margin: 15px 25px 0 25px;
    text-align: left;
    padding: 16px 30px;
}

.filter-modal-container {
    border: 0;
    padding: 16px;
    margin: 0 20px;
}

/* Filter Dropdown Styles */
.filter-container .MuiBox-root {
    margin: 0 auto;
}

.filter-container .MuiGrid-root {
    width: 100%;
    margin-left: 0;
}

.filter-container .accordion-content .MuiGrid-root.MuiGrid-item {
    padding-left: 0;
    padding-top: 0;
}

.filter-container .accordion-content .MuiPaper-root {
    padding: 10px 30px 0 0;
}

.filter-container .accordion-content .MuiPaper-root.filterGroup {
    padding: 0 30px 10px 0;
}

.filter-container .MuiPaper-root.filterGroup label {
    display: block;
    padding-bottom: 5px;
    text-align: left;
    font: normal normal normal 12px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #758490;
}

.filter-container .MuiPaper-root.filterGroup label.required::after {
    content: "*";
    color: red;
}

.dropdown-wrapper {
    .dropdown-button {
        padding: 8px 17px 8px 20px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #acacac;
        border-radius: 3px;
        height: 35px;
        -webkit-box-align: baseline;
        align-items: baseline;
        cursor: pointer;
        display: inline-flex;
        margin: 0px;
        max-width: 100%;
        flex-wrap: nowrap;
        text-align: left;
        text-decoration: none;
        transition: background 0.1s ease-out 0s,
            box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
        vertical-align: middle;
        white-space: nowrap;
        outline: currentcolor none medium !important;
        width: 100% !important;
        font: normal normal normal 14px/26px Poppins, sans-serif;
    }

    .dropdown-button.placeholder {
        color: #c4c4c4;
    }

    .dropdown-button.selected {
        border: 1px solid #0055af;
        color: #1d1d1d;
    }

    .dropdown-button.disabled-button {
        background-color: #eee;
    }

    span.selected-text {
        -webkit-box-align: center;
        align-items: center;
        align-self: center;
        flex: 1 1 auto;
        margin: 0px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .dropdown-button[isselected="true"] span.selected-text {
        color: #1d1d1d;
    }

    .dropdown-button + div > div {
        background: #ffffff 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 0px 6px #00000029 !important;
        border: 1px solid #e2e2e2 !important;
        border-radius: 3px !important;
        width: 100%;
    }

    span.toggle-icon {
        align-self: center;
        display: flex;
        flex-shrink: 0;
        line-height: 0;
        font-size: 0px;
        margin: 0px 4px;
    }

    .search-select__control {
        // min-width: 180px;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%);
        border-style: solid;
        border-width: 0 0 1px 0;
        cursor: default;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 28px;
        outline: 0 !important;
        position: relative;
        -webkit-transition: all 100ms;
        transition: all 100ms;
        box-sizing: border-box;
        flex-direction: row-reverse;
        padding: 2px 14px;

        .search-select__value-container {
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex: 1;
            -ms-flex: 1;
            flex: 1;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-overflow-scrolling: touch;
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            text-align: left;
            font: normal normal normal 14px/26px Poppins, sans-serif;
            color: #7584904d;
            padding-left: 6px;

            .multi-select__input {
                #search-select-input {
                    box-sizing: content-box;
                    border: 0px;
                    opacity: 1;
                    outline: 0px;
                    padding: 0px;
                    font: normal normal normal 14px/26px Poppins, sans-serif;
                    position: relative;
                    top: 2px;
                    color: #758490;
                }
            }
        }
    }
}

#dropdown-clear-btn label,
#select-all-btn label {
    letter-spacing: 0px;
    color: #758490;
    cursor: pointer !important;
    text-align: left;
    font: normal normal normal 14px/26px Poppins, sans-serif;
    padding: 0 0 0 4px;
}

#select-all-btn svg {
    height: 15px;
}

.dropdown-wrapper-disabled {
    .dropdown-button {
        background: #f5f5f5;
    }
    .filter-options-group,
    .search-select__control,
    .multi-select-container {
        pointer-events: none;
    }
}

.filter-no-data {
    font: normal normal normal 16px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #758490;
    margin: 0 auto;
}

.filter-buttons {
    gap: 20px;
    margin-top: 15px;
}

.filter-buttons button {
    margin: 0;
    width: 90px;
    height: 35px;
    border-radius: 3px;
}

.filter-buttons button.save-filters {
    width: 112px;
}

/* ---- Filter Modal Styles ----- */
.filterWrapper {
    position: relative; // ""
    z-index: 2;
}

.filterOverlay {
    background: rgba(29, 29, 29, 0.7);
    position: absolute;
    top: 0;
    transform: translateY(-150%);
    visibility: hidden;
    width: 100%;
    z-index: 801;
    height: calc(100vh - 20px);
}

.modalOpen {
    top: -68px;
    transform: translateY(0);
    visibility: visible;
}

.modalContainer {
    background: #fff;
}

.modalContainer .MuiGrid-root.MuiGrid-container.filter-modal-title-container {
    padding: 0 25px;
    width: unset;
    align-items: center;
    border-bottom: 1px solid #c8ced7;
    height: 65px;
}

button.closeText {
    text-align: right;
    font: normal normal 600 16px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #0055af;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
}

.filter-modal-label {
    text-align: left;
    font: normal normal 600 18px/27px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #1d1d1d;
    margin: 0;
}

.filter-container .DateRangePicker .DateRangePickerInput {
    width: 222px !important;
    padding: 0 5px;
}
