.DateRangePicker .DateRangePickerInput {
    width: 300px;
    height: 35px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #acacac;
    border-radius: 3px;
    padding: 0 20px;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    // flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.DateRangePicker .DateRangePickerInput .DateInput_input {
    text-align: left;
    font: normal normal normal 14px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #c4c4c4;
    margin: 0;
    box-sizing: border-box;
    border-bottom: 0;
    padding: 0 4px;
}

.DateRangePicker .DateRangePickerInput .DateInput_input::placeholder {
    color: #c4c4c4;
}

.DateRangePicker
    .DateRangePickerInput
    .DateInput_input:not(:placeholder-shown) {
    color: #1d1d1d;
}

.DateRangePicker button.DateRangePickerInput_clearDates {
    display: none;
}

.DateRangePicker
    .DateRangePickerInput
    button.DateRangePickerInput_calendarIcon {
    padding: 0;
    margin: 0;
}

.DateRangePicker
    .DateRangePickerInput
    button.DateRangePickerInput_calendarIcon
    svg {
    fill: #758498;
    height: 17px;
    width: 15px;
    vertical-align: baseline;
}

.DateRangePicker_picker {
    z-index: 1301;
}

.DateRangePickerInput_arrow {
    font: normal normal normal 14px/26px Poppins, sans-serif;
    color: #c4c4c4;
}

.DateRangePickerInput_arrow_svg {
    height: 14px;
    width: 14px;
}

.DateInput {
    width: 100px;
}

.CalendarMonth_caption {
    text-align: center;
    font: normal normal 600 16px/25px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #1d1d1d;
}

.CalendarDay {
    text-align: center;
    font: normal normal normal 14px/21px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #1d1d1d;
}

.CalendarDay__default {
    color: #1d1d1d;
    border: none;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    border: none;
    color: #cacccd;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #0055af 0% 0% no-repeat padding-box;
    border: none;
}

.CalendarDay__selected_span {
    background: #eff0f8;
    border: none;
    color: #1d1d1d;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
    background: #0055af;
    border: none;
    color: #1d1d1d;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover,
.CalendarDay__default:hover {
    background: #eff0f8;
    border: none;
    color: #1d1d1d;
}

.DayPickerNavigation_button__default {
    border: none;
    color: #1d1d1d;
}

.DayPickerNavigation_svg__horizontal {
    fill: #1d1d1d;
}

.SingleDatePicker .SingleDatePickerInput {
    width: 300px;
    height: 35px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #acacac;
    border-radius: 3px;
    padding: 0 20px;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    // flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.SingleDatePicker .SingleDatePickerInput .DateInput_input {
    text-align: left;
    font: normal normal normal 14px/26px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #c4c4c4;
    margin: 0;
    box-sizing: border-box;
    border-bottom: 0;
    padding: 0 4px;
}

.SingleDatePicker .SingleDatePickerInput .DateInput_input::placeholder {
    color: #c4c4c4;
}

.SingleDatePicker
    .SingleDatePickerInput
    .DateInput_input:not(:placeholder-shown) {
    color: #1d1d1d;
}

.SingleDatePicker button.SingleDatePickerInput_clearDates {
    display: none;
}

.SingleDatePicker
    .SingleDatePickerInput
    button.DateRangePickerInput_calendarIcon {
    padding: 0;
    margin: 0;
}

.SingleDatePicker
    .SingleDatePickerInput
    button.SingleDatePickerInput_calendarIcon
    svg {
    fill: #758498;
    height: 17px;
    width: 15px;
    vertical-align: baseline;
}

.DateRangePicker .DateRangePickerInput.DateRangePickerInput__disabled {
    background: #f2f2f2;
}
