.wrapper {
    position: fixed;
    top: 107px;
    left: 72px;
    width: calc(100% - 72px);
    max-height: calc(100% - 200px);
    overflow: scroll;
    z-index: 100;

    & * {
        box-sizing: border-box;
    }
}

.container {
    height: 100%;
    background-color: #fff;
}

.header {
    padding: 16px;
}

.title {
    color: #1d1d1d;
    font-size: 18px;
    font-weight: 600;
}

.tertiary-button {
    font-size: 14px;
    font-weight: bold;
}

.divider {
    height: 2px;
    background: #ebecf0;
    margin: 0px 16px;
}

.body {
    height: calc(100% - 68px);
    text-align: left;
    overflow: auto;

    & :global .MuiAccordion-root::before {
        background-color: transparent;
    }

    & :global .MuiPaper-root {
        padding: 0px;
        &.filterGroup {
            padding-bottom: 8px;
        }
    }

    & :global .MuiPaper-root.filterGroup label {
        display: block;
        text-align: left;
        font: normal normal normal 12px Poppins, sans-serif;
        letter-spacing: 0px;
        color: #758490;
    }
}

.action-container {
    padding: 16px 16px 12px 16px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;
}

.save-container {
    display: inline-block;
    margin-top: 24px;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px #999;
}

.filter-name {
    width: 200px;
    margin-right: 24px;
}

.save-action-container {
    margin-top: 20px;
}
