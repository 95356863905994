.overlayContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.optimization-loader .MuiBackdrop-root {
    background-color: rgb(255 255 255 / 81%);
}

.brewingGIF {
    width: 40%;
    height: 18rem;
    top: 2rem;
    position: relative;
    border-radius: 5px;
}

.imageText {
    text-align: left;
    font: normal normal 600 16px/25px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #1d1d1d;
    position: relative;
    top: 2rem;
}

.overlayText {
    margin-top: 2rem;
    font-size: 16px;
}

.divBtn {
    cursor: pointer;
    color: #537385;
    margin: 2rem 0;
    font-size: 15px;
}

.showOptions {
    color: #537385;
    background-color: #e1ebf0;
    display: inline-block;
    position: relative;
    padding: 0 3rem;
    border-radius: 2px;
    margin-bottom: 1rem;
    cursor: pointer;
}

.btnSection {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.createAnotherPlanBtn {
    color: white;
    background-color: #44677b;
    border: 1px solid transparent;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;
}

.goToScrBtn {
    color: #44677b;
    background-color: white;
    border: 1px solid #44677b;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
    padding: 0.5rem 1.5rem;
}

.goToScrBtn:hover,
.goToScrBtn:active {
    color: white;
    background-color: #44677b;
    border: 1px solid transparent;
}

.createdropdownOption {
    margin: 10px;
    text-align: left !important;
    font-size: 12px;
    cursor: pointer;
}

.genOverlayAngleDown1 {
    padding-left: 10px;
    font-size: 16px;
}

.genOverlayAngleDown2 {
    font-size: 20px;
}
