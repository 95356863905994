.modal-container {
    background: white;
    box-sizing: border-box;
    padding: 1.5rem 2rem;
    position: relative;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.title {
    font-size: 1.5rem;
    margin-top: 0;
}

.close-icon {
    position: absolute;
    top: 5px;
    right: 8px;
}
