.container {
    // width: 280px;
    width: 100%;
}

.icon-container {
}

.exception,
.alert {
    margin: 0px 4px;
    padding: 2px;
    border-radius: 50%;
    box-shadow: 0px 0px 3px #666;
}

.exception {
    // margin: 0px 24px;
    color: #000;
    background-color: #fbbd4c;

    &.mandatory {
        color: #fff;
        background-color: #da1e28;
    }
}

.alert {
    color: #fff;
    background-color: #ff832c;
}

.popover-container {
    width: 200px;
    padding: 0px 12px;
    font-size: 12px;
    border-radius: 3px;
    background-color: #fff;
}

.popover-title {
    margin-bottom: 12px;
    font-weight: 600;
}

.popover-dont-show-again-container {
    margin-left: -10px;
    margin-bottom: -10px;
}

.popover-divider {
    height: 1px;
    margin: 12px 0px;
    background-color: #e2e2e2;
}

.action-container {
    margin-top: 12px;
    padding-left: 12px;
}

.status-container {
    // padding: 0px 4px;

    .not-accessible,
    .being-generated,
    .being-synced {
        margin: 0px 2px;
    }

    .not-accessible {
        color: #d00;
    }

    .being-generated {
        color: #fbbd4c;
    }

    .being-synced {
        color: #0457b0;
    }
}

.disabled {
    filter: grayscale(100%);
    opacity: 0.3;
}
