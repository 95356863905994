.home-link {
    font: normal normal 600 20px/30px Poppins, sans-serif;
    color: #1d1d1d;
    text-align: left;
    text-decoration: none;
}

.home-link:visited {
    color: #1d1d1d;
}

.navbar-brand {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.navbar-nav {
    list-style-type: none;
    display: flex;
    flex-direction: row;
}

.nav-item {
    color: #1d1d1d;
    font: normal normal normal 16px/26px Poppins, sans-serif;
    padding-left: 20px;
    margin: auto;
}

.notification-drawer {
    position: fixed;
    top: 48px;
    right: 4%;
    z-index: 999;
    width: 30rem;
    min-width: 26rem;
    min-height: 30rem;
    padding: 24px;
    box-sizing: border-box;
    box-shadow: 0 5px 13px -3px #e0e3e7;
    background: #fff;
    border: 1px solid #cdd2d7;
    border-radius: 4px;

    .notifications-title {
        text-align: left;
        font: normal normal 600 18px/27px Poppins, sans-serif;
        letter-spacing: 0px;
        color: #1d1d1d;
        margin: 0 0 20px 0;
    }

    ul {
        list-style: none;
        font-size: 0.8rem;
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        color: rgb(29, 29, 29);
        overflow: auto;
        outline: 0px;
        height: 30rem;

        li {
            padding: 16px;
            text-align: left;
            border-radius: 3px;
            background-color: #e5edf7;
            margin-bottom: 10px;

            .link {
                color: #0054af;
                text-decoration: underline;
                cursor: pointer;
            }

            .multiple-items {
                font-style: italic;
                font-weight: 500;
            }
        }
    }

    .notification-type {
        margin: 0 0 10px 0;
        margin: 0 auto;
        text-align: left;
        font: normal normal 600 16px/25px Poppins, sans-serif;
        letter-spacing: 0px;
        color: #1d1d1d;
    }

    .notification-message {
        margin: 0;
        text-align: left;
        font: normal normal normal 14px/21px Poppins, sans-serif;
        letter-spacing: 0.08px;
        color: #1d1d1d;
    }

    .notification-time {
        margin: 20px 0 0 0;
        text-align: left;
        font: normal normal normal 14px/21px Poppins, sans-serif;
        letter-spacing: 0.08px;
        color: #1d1d1d;
    }

    p.no-notifications {
        color: #44677b;
        font-size: 0.8rem;
        padding: 10px;
        margin: 0;
        box-shadow: 0 5px 13px -3px #e0e3e7;
        background: #fff;
        border: 1px solid #cdd2d7;
        border-radius: 4px;
    }
}

.notification-count {
    width: 8px;
    height: 8px;
    background: #ff4415 0% 0% no-repeat padding-box;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    top: -18px;
    left: -10px;
}

.breadcrumb-container {
    position: sticky;
    top: 72px;
    height: 35px;
    background: #ebecf0 0% 0% no-repeat padding-box;
    padding: 0px 20px;
    display: flex;
    z-index: 100;
}

.breadcrumb-container .MuiTypography-root {
    text-align: left;
    font-size: 14px/21px;
    letter-spacing: 0px;
    color: #0055af;
}

.breadcrumb-container .MuiTypography-root.active {
    color: #1d1d1d;
}

.marquee {
    width: 100%;
    background-color: #fff;
    line-height: 15px;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    margin-top: -1.2rem;
    padding-bottom: 0.5rem;
    span {
        font-weight: 400;
        line-height: 1.2em;
        color: #333;
        font-size: 0.75rem;
        font-style: italic;
        margin: 0;
        display: inline-block;
        padding-left: 100%;
        animation: marquee 30s linear infinite;
    }
}

.marquee span:hover {
    animation-play-state: paused;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

/* Respect user preferences about animations */

@media (prefers-reduced-motion: reduce) {
    .marquee span {
        animation-iteration-count: 1;
        animation-duration: 0.01;
        /* instead of animation: none, so an animationend event is 
	   * still available, if previously attached.
	   */
        width: auto;
        padding-left: 0;
    }
}

.collapse:not(.show) {
    display: none;
}

.update-banner {
    height: 65px;
    min-height: 65px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #da1e28;
    border: 1px solid #da1e28;
    background: #fce9ea;
    position: relative;
}
