.container {
    width: 100%;
    height: 100%;
}

.main-container {
    min-height: 0px;
    padding: 20px;

    .header {
        margin-bottom: 20px;

        .title {
            font-size: 18px;
            font-weight: bold;
        }

        .close-btn {
            margin: -12px 0px;
            color: #999;
            font-size: 36px;
            border: none;
            background-color: white;
        }
    }
}

.sub-title {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
}

.view-container {
    height: 100%;

    .col-width-mode-container {
        :global .MuiFormControlLabel-label {
            color: #758490;
        }

        :global .Mui-checked + .MuiFormControlLabel-label {
            color: inherit;
        }
    }
}

.name-container {
    height: 30px;
    padding: 0px 12px;
    color: #758498;
    font-size: 12px;
    background-color: #efefef;
    border-radius: 15px;
    border: none;

    &.active-name {
        color: #ffffff;
        background-color: #0055af;
    }
}

.column-name {
    color: #758490;
    font-size: 14px;
}

.divider {
    height: 2px;
    background: #ebecf0;
}

.actions-container {
    padding: 20px;
}

.pin-container {
    margin-right: 12px;
    width: 26px;
    border: none;
    background-color: white;
    cursor: pointer;
}

.draggable-section {
    display: flex;
    padding: 8px;
    height: calc(23vh - 104px);
    overflow-x: hidden;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    align-content: flex-start;
    background-color: white;
    width: 100%;
}

.chip {
    color: #ffffff;
    background-color: #0055af;
    padding: 2px 7px;
    border-radius: 5px;
    font-size: 12px;

    span {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
.buying-roll-up {
    overflow: auto;
}
.buying-roll-up-sub-title {
    font-size: 12px;
    font-weight: 400;
    color: #758490;
}

.half-width {
    width: calc(49% - 5px);
    margin-bottom: 15px;
}
