.container {
    padding: 20px;
}

.header-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
}

.header-label {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin: 0;
}

.download-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #afafaf;
}

.active-download-link {
    color: #0055af;
    cursor: pointer;
}

.status-dot {
    border-radius: 50%;
    min-height: 12px;
    min-width: 12px;
    vertical-align: text-bottom;
    display: inline-block;
    margin-right: 6px;
}
