.drawer-container {
    // position: relative;
    // overflow-y: scroll;
    // padding: 20px 32px;
    // background-color: #fff;
    height: 100%;

    .header {
        padding: 24px 20px;
        border-bottom: 1px solid #ebecf0;

        .title {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
        }

        .close-icon {
            color: #999;
        }
    }

    .body {
        padding: 24px 20px;

        .sub-title {
            font-size: 12px;
            white-space: break-spaces;
        }

        .paste-area {
            display: block;
            width: 100%;
            height: 180px;
            padding: 8px 16px;
            font: normal normal normal 12px/26px Poppins, sans-serif;
            resize: none;
            box-sizing: border-box;
            border-color: #acacac;

            &::placeholder {
                color: #999;
            }
        }

        .invalid-values-container {
            width: 100%;
            max-height: 180px;
            padding: 8px 16px;
            color: #555;
            box-sizing: border-box;
            white-space: break-spaces;
            border: 1px solid #acacac;
            border-radius: 3px;
            overflow: auto;
        }

        .info {
            padding: 16px 12px;
            font-size: 14px;
            background-color: #ebecf0;
        }
    }

    .action-container {
        padding: 24px 20px;
        justify-content: end;
        border-top: 1px solid #ebecf0;

        & > * {
            margin: 0px 6px !important;
        }
    }
}
