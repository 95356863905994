.accordion-container {
    box-shadow: none !important;
    border: 0 !important;
    padding: 16px 16px 16px 32px !important;
}

.accordion-header {
    padding: 0 !important;
    justify-content: left !important;
    min-height: 23px !important;
}

.accordion-header .MuiAccordionSummary-expandIconWrapper {
    margin-left: 5px;
}

.accordion-header .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
}

.accordion-header .MuiAccordionSummary-content {
    flex-grow: unset;
    margin: 0 0;
}

.accordion-header .accordion-title {
    margin: 0 auto;
    text-align: left;
    font: normal normal 400 16px/25px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #1d1d1d;
}

.Mui-expanded > .accordion-title {
    font-weight: 500;
}

.accordion-container .MuiCollapse-wrapper {
    margin-top: 12px;
}

.accordion-content {
    padding: 0;
}

.MuiAccordionDetails-root.accordion-content::before {
    border-bottom: 0px;
    content: "";
    position: absolute;
    left: 15px;
    height: calc(100% - 20px);
    width: 2px;
    background: #0055af;
    top: 10px;
    visibility: visible;
}
