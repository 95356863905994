@import "./variables.scss";
@import "./mixins.scss";
$headerHeight: pxToRem(80);

.hr-text {
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0px;
    font-size: 1rem;
    letter-spacing: 0px;
    font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
}

.marketing {
    &-container {
        background: $white url("../../assets/images/Marketing_BG.png")
            center/cover;
        box-shadow: -0.25rem 0 0.75rem -0.5rem rgba(0, 0, 0, 0.3);
        padding: 3.4rem;
        text-align: left;
        font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
        font-size: 14px;
        box-sizing: border-box;
    }

    &__header {
        margin: 0px;
        color: #1d1d1d;
        font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
        font-size: 24px;
        font-weight: 400;
        text-size-adjust: 100%;
        line-height: 2.25rem;
        letter-spacing: 0.04em;
        box-sizing: border-box;
    }

    &__image {
        width: 100%;

        &-wrapper {
            margin: 0 auto;
            max-width: pxToRem(420);
            padding: 5.375rem 0;
        }
    }

    &__title {
        box-sizing: border-box;
        color: rgb(29, 29, 29);
        font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: normal;
        line-height: 24px;
    }

    &__description {
        color: rgb(29, 29, 29);
        font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
        font-size: 14px;
        letter-spacing: normal;
        line-height: 17px;

        &-wrapper {
            margin: 0 auto 1.5rem;
            max-width: pxToRem(540);
            padding: 0 3rem;
            text-align: center;
        }
    }

    &__indicators {
        text-align: center;

        .icons {
            color: #c8c7c7;
            cursor: pointer;
            font-size: 0.625rem;
            margin: 0 0.3rem;

            &.active {
                color: #0055af;
                cursor: default;
                pointer-events: none;
            }
        }
    }
}

.signin-form {
    &__header {
        background-color: $white;
        box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.05);
        display: flex;
        justify-content: space-between;
        height: $headerHeight;
        padding: 1.25rem 1.875rem;
        position: relative;
        box-sizing: border-box;

        .header__logo {
            height: 100%;
        }

        .brand__title {
            color: #1d1d1d;
            font-weight: 400;
            letter-spacing: 0.02em;
            line-height: normal;
            font-size: 1.5rem;
            font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
        }
    }

    &-container {
        background-color: $white;
        padding: 3.4rem;
        text-align: left;
        box-sizing: border-box;

        .sign-in-text {
            font-weight: 400;
            line-height: 1.5;
            margin-bottom: 3rem;
            font-size: 1.5rem;
            letter-spacing: 0px;
            font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
        }

        .signin-form {
            .btn {
                display: flex;
                margin-right: 0px;
                margin-bottom: 1.825rem;
                margin-left: auto;
                padding: 0;
                height: 22px;
                font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
                font-size: 14px;
                font-weight: 700;
                text-size-adjust: 100%;
                letter-spacing: normal;
                line-height: 22px;
            }

            .btn-signin {
                height: 38px;
                margin: 0px;
                padding-bottom: 0.5rem;
                padding-top: 0.5rem;
                font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
                font-size: 14px;
                font-weight: 700;
                text-size-adjust: 100%;
                letter-spacing: normal;
                line-height: 22px;
            }

            :global .MuiInputLabel-root {
                color: #1b2e42;
                font-weight: 600;
                margin-bottom: 0.25rem;
                font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
                font-size: 0.875rem;
                line-height: 1.4375em;
                letter-spacing: normal;
                text-size-adjust: 100%;
            }

            :global .MuiInputBase-input {
                @include font-size-body1();
                color: #475569;
                font-weight: 300;

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 62rem white inset !important;
                }
            }
        }

        .login-options {
            margin: 0 auto;
            padding: pxToRem(50);
            max-width: pxToRem(520);
            border: 1px solid #dbdbdb;
            border-radius: 0.5rem;

            .login__wrapper {
                max-width: 20rem;
                margin: 0 auto;
            }

            :global .MuiDivider-root {
                justify-content: center;
                color: #6e7e95;
                // margin-top: 1rem;

                &::after,
                &::before {
                    width: 0.5rem;
                    border-color: #6e7e95;
                }
            }
        }

        .firebase-sign-in,
        .firebase-without-emailLogin {
            margin-top: 1rem;

            .firebaseui-card-content {
                padding: 0;
            }

            .firebaseui-idp-list {
                display: flex;
                flex-wrap: wrap;
                gap: 0 1rem;
                justify-content: center;
                margin: 0;

                .firebaseui-list-item {
                    flex-grow: 1;
                    flex-basis: calc(50% - 1rem);
                    white-space: nowrap;
                    margin-bottom: 1rem;

                    .firebaseui-idp-text {
                        @include font-size-body1();
                        color: #475569;
                    }

                    &:first-child {
                        .firebaseui-idp-text-long {
                            display: none;
                        }

                        .firebaseui-idp-text-short {
                            display: table-cell;
                        }
                    }
                }
            }
        }

        .contact {
            margin-top: 16px;
            color: #87919a;

            a {
                margin: 0px;
                color: rgb(0, 85, 175);
                text-decoration: none;
            }
        }
    }
}

.h-md-100 {
    min-height: calc(100vh - $headerHeight);
    height: 100%;
}

.error-text {
    color: red;
    font-size: 0.75rem;
    margin-top: pxToRem(3);
}

.error-border {
    border: 1px solid #ffc0cb !important;
    box-shadow: 0 1px 1rem 1px rgb(255 0 0 / 7%);
}

.input-group {
    text-align: left;
    margin-bottom: 1rem;
}
