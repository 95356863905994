.wrapper {
    display: flex;
    flex-direction: row;
}

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex: 1;
    width: 88%;
}

.main-content {
    flex: 1 1 auto;
    position: relative;
    height: 0px;
    background: #fff;
}

.layout-modal {
    background: white;
    box-sizing: border-box;
    padding: 1.5rem 2rem;
    position: relative;
}

.layout-modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}
