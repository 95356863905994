.body {
    & :global .MuiAccordion-root::before {
        background-color: transparent;
    }

    & :global .MuiPaper-root {
        padding: 0px;
        &.filterGroup {
            padding-bottom: 8px;
        }
    }

    & :global .MuiPaper-root.filterGroup label {
        display: block;
        text-align: left;
        font: normal normal normal 12px Poppins, sans-serif;
        letter-spacing: 0px;
        color: #758490;
    }

    .filter-container {
        margin-top: 20px;
        border: 1px solid #c8ced7;
        border-radius: 3px;
    }

    .or-container {
        margin-top: 24px;

        .or-content {
            margin: 0px 20px;
            font-weight: 600;
        }
    }

    .copy-section {
        margin-top: 16px;
        padding: 16px 18px 18px;
        text-align: left;
        border: 1px solid #c8ced7;
        border-radius: 3px;

        .title {
            margin-bottom: 16px;
            font-weight: 600;
            line-height: 24px;
        }

        .desc {
            margin-bottom: 12px;
            color: #333;
            font-size: 14px;
        }
    }
}

.drawer-container {
    position: relative;
    overflow-y: scroll;
    padding: 20px 32px;
    background-color: #fff;

    .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
    }

    .close-icon {
        color: #999;
    }

    .sub-title {
        font-size: 12px;
        white-space: break-spaces;
    }

    .dropdown {
        width: 300px;
    }

    .paste-area {
        display: block;
        width: 100%;
        height: 180px;
        padding: 8px 16px;
        font: normal normal normal 12px/26px Poppins, sans-serif;
        resize: none;
        box-sizing: border-box;
        border-color: #acacac;

        &::placeholder {
            color: #999;
        }
    }

    .invalid-values-container {
        width: 100%;
        max-height: 180px;
        padding: 8px 16px;
        color: #555;
        box-sizing: border-box;
        white-space: break-spaces;
        border: 1px solid #acacac;
        border-radius: 3px;
        overflow: auto;
    }
}

.paste-alert {
    .MuiAlert-icon {
        margin: 15px;
    }
}