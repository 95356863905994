.container {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    color: #1d1d1d;
}

.page-header {
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 600;
}

.page-title {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    margin: 0;
}
