.box {
    border: 1px solid #c8ced7;
    border-radius: 3px;
    padding: 16px 20px 25px;
    text-align: left;
}

.step-title {
    margin-top: 0;
    margin-bottom: 14px;
    font: normal normal 600 16px/25px Poppins, sans-serif;
    letter-spacing: 0px;
    color: #1d1d1d;
}

.hr {
    margin: 0 16px;
    height: 1px;
    background: #ebecf0;
}

.hr-with-margin {
    margin: 26px 0px 20px;
    height: 1px;
    background: #ebecf0;
}

.text-field {
    :global .MuiOutlinedInput-root {
        padding: 6px 0px;
    }
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;

    :global .MuiStepLabel-label {
        font: normal normal normal 14px/20px Poppins, sans-serif;
    }

    :global .MuiStepIcon-root {
        width: 40px;
        height: 40px;
        color: #758490;
    }

    :global .Mui-completed:not(.MuiStepLabel-label) {
        color: rgb(0, 85, 175);
    }

    :global .Mui-active {
        font-weight: bold;
    }
}

.editIcon {
    color: white;
    background-color: rgb(0, 85, 175);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 10px;

    @media only screen and (max-width: 1510px) {
        padding: 8px;
    }
}

.nav {
    display: flex;
    margin-bottom: 12px;
}

.stepper {
    flex-grow: 7;
    margin: 16px 0px 12px;
}

.nav-return {
    flex-grow: 5;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    justify-content: flex-end;
    cursor: pointer;
    color: rgb(0, 85, 175);
    font-size: 14px;
}

.nav-return-icon {
    rotate: 90deg;
}

.page-title {
    color: #1d1d1d;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 14px;
}

.stepper-body {
    margin-top: 28px;
}

.action-container {
    margin-top: 20px;
}

.dropzone-container {
    padding: 70px 40px 117px;
    background-color: #fafcff;
    border: 1px dashed #0054af;
    border-radius: 3px;
    box-sizing: border-box;
    margin: 23px 15px 10px;
    font-weight: 500;
    color: #758490;
    cursor: pointer;
}

.upload-icon-container {
    width: 91px;
    height: 91px;
    color: #0054af;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
}

.upload-file-name {
    font-weight: 500;
}

.button-link {
    color: #0054af;
    font-weight: 600;
    cursor: pointer;
    border: none;
    background: white;
    font-size: 16px;

    &:hover {
        color: #033162;
    }
}

.absolute-right-buttons {
    position: absolute;
    right: 16px;
    top: 10px;
}

.tooltip {
    overflow-x: clip;
}

.invalid-material-message {
    padding: 16px;
    background-color: #fce9ea;
    border: 1px solid #da1e28;
    display: flex;
    gap: 16px;
    width: fit-content;
    min-width: 30%;
    padding-right: 50px;
    text-align: left;
    margin-top: 16px;
}

.download-invalid-materials {
    padding-top: 8px;
    display: flex;
    color: #0055af;
    width: fit-content;
    gap: 8px;
    font: normal normal 600 14px/24px Poppins, sans-serif;
    align-items: center;
    cursor: pointer;
}

.modal-container {
    position: relative;
    top: 50%;
    left: 50%;
    width: 440px;
    padding: 24px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 5px;
    transform: translate(-50%, -50%);

    &.upload-modal {
        width: 800px;
    }
}

.modal-actions-container {
    margin-top: 24px;
    text-align: right;
    justify-content: end;
}

.upload-file-details-container {
    display: flex;
    width: 500px;
    margin-top: 17px;
    padding: 24px 20px 26px;
    background-color: #f5faff;
}

.upload-file-icon {
    padding: 14px;
    color: #0054af;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 50%;

    &.upload-file-icon-xls {
        height: 49px;
        width: 49px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.upload-file-details-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
}

.upload-file-details-title {
    color: #758490;
    font-size: 14px;
    line-height: 24px;
    padding-top: 16px;
}

.upload-file-change {
    margin-top: 15px;
    color: #0055af;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.upload-price-file-title {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
}

.no-padding-top {
    padding-top: 0 !important;
}

.page-sub-title {
    color: #1d1d1d;
    font-size: 16px;
    line-height: 27px;
    font-weight: 600;
}
