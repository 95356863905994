/* Hide scrollbar */
.drawer .sidebarToggleIcon.left {
    cursor: pointer;
    display: flex;
    position: fixed;
    left: 322px;
    transition: left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    border: 2px solid #ffffff;
    bottom: 15%;
    z-index: 999;
    transform: translateX(-50%);
    background: #0055af;
    border-radius: 50%;
    .MuiSvgIcon-root {
        width: 22px !important;
        height: 22px !important;
    }
}

.drawer .sidebarToggleIcon.right {
    cursor: pointer;
    display: flex;
    position: fixed;
    left: 72px;
    transition: left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    border: 2px solid #ffffff;
    bottom: 15%;
    z-index: 999;
    transform: translateX(-50%);
    background: #0055af;
    border-radius: 50%;
    .MuiSvgIcon-root {
        width: 22px !important;
        height: 22px !important;
    }
}

.drawer .Mui-selected {
    background-color: #0055af !important;
}

.drawer::-webkit-scrollbar,
.MuiDrawer-paper::-webkit-scrollbar {
    display: none;
}

.drawer,
.MuiDrawer-paper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background-color: #033162 !important;
}

.drawerOpen {
    width: 322px;
    overflow-x: hidden;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.drawerClose {
    width: 72px;
    overflow-x: hidden;
    transition: width 125ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.drawer .logo {
    overflow: hidden;
    text-align: left;
    padding-left: 20px;
    display: flex;
    align-items: center;
    height: 71px;

    &.drawerOpen {
        padding-left: 18px;
    }
}

.drawer .sidebarMenu {
    width: 100%;
    text-decoration: none;
}

.drawer .sidebarMenu.active {
    svg {
        fill: #fff;
    }
    background-color: #0055af;
}

.drawer .listitemSidebar {
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 20px 24px;

    &:hover {
        color: white;
        background-color: rgba(0, 85, 175, 0.3);
    }
}

.drawer .listitemSidebar + ul {
    padding: 0px;
}

.MuiListItemIcon-root {
    min-width: 36px;
}

.drawer .MuiSvgIcon-root {
    width: 22px !important;
    height: 22px !important;
}

.drawer .menuItem {
    // height: 50px;
    padding: 0;
}

ul {
    list-style: none;
}

.drawer .sub-menu-item {
    padding: 8px 0px 8px 82px;
    color: #fff;
    font-size: 14px;
    text-align: left;
    cursor: pointer;

    &.active {
        color: #05accb;
    }

    &:hover {
        background-color: rgba(0, 85, 175, 0.3) !important;
    }
}

.drawer .MuiListItemText-root {
    text-align: left !important;
    font: normal normal 600 14px/21px Poppins, sans-serif !important;
    letter-spacing: 0px !important;
    color: #ffffff !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.drawerClose .MuiListItemText-root {
    display: none;
}

.drawer .sidebarFooter {
    position: absolute;
    bottom: 0;
    color: white;
    padding: 0 0 14px 24px;
}

.drawer .sidebarFooter > a {
    color: #fff;
}

#sidebar-drawer .MuiDrawer-paper {
    padding: 0;
}
